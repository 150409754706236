// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agendar-js": () => import("./../../../src/pages/agendar.js" /* webpackChunkName: "component---src-pages-agendar-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-planes-eliana-js": () => import("./../../../src/pages/planes-eliana.js" /* webpackChunkName: "component---src-pages-planes-eliana-js" */),
  "component---src-pages-planes-js": () => import("./../../../src/pages/planes.js" /* webpackChunkName: "component---src-pages-planes-js" */),
  "component---src-pages-preguntas-js": () => import("./../../../src/pages/preguntas.js" /* webpackChunkName: "component---src-pages-preguntas-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-receta-index-js": () => import("./../../../src/templates/receta-index.js" /* webpackChunkName: "component---src-templates-receta-index-js" */),
  "component---src-templates-receta-template-js": () => import("./../../../src/templates/receta-template.js" /* webpackChunkName: "component---src-templates-receta-template-js" */)
}

